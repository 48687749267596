import React from 'react'
import Markdown from 'markdown-to-jsx'
import Image from '../Image'
import styles from './case-user-story.module.sass'

const Blank = () => {
  return ''
}

const Item = ({ children }) => {
  return <li className={children[0].length > 25 ? 'long' : ''}>{children}</li>
}

export default function CaseUserStory({ title, mapping }) {
  return (
    <section id="user-story" className={styles.userStory}>
      <div className="container">
        <h2 className={styles.sectionTitle}>{title}</h2>
        <div className={styles.sectionStories}>
          {mapping.map(item => (
            <div className={styles.mappingItem}>
              <div className={styles.userGoals}>
                <div className={styles.user}>
                  <figure className={styles.userImage}>
                    <Image src={item.user.image} alt={item.user.name} />
                  </figure>
                  <h3>{item.user.name}</h3>
                  <span>{item.user.company}</span>
                </div>
                <div className={styles.goals}>
                  <h3>Goals</h3>
                  <ul>
                    {item.user.goals.map(goal => (
                      <li>{goal}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={styles.story}>
                <h3>{item.story.title}</h3>
                <div className={styles.storyColumns}>
                  {item.story.set.map((item, i) => (
                    <Markdown
                      className={styles.storySet}
                      children={item}
                      options={{
                        forceWrapper: false,
                        wrapper: 'div',
                        overrides: {
                          Blank,
                          li: {
                            component: Item
                          }
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
// options={{
//   overrides: {
//     a: {
//       props: {
//         className: styles.viewProfile
//       }
//     }
//   }
// }}
