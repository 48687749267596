import React from 'react'
import classNames from 'classnames'
import Image from '../Image'
import Markdown from 'markdown-to-jsx'

import styles from './case-hero.module.sass'

export default function HeroSection({ content, image, classes, background, images }) {
  return (
    <section className={classNames(styles.caseHero, classes && classes.split().map(item => styles[item]))} style={{ backgroundColor: background }}>
      <div className="container">
        <div className={classNames('row', styles.heroRow)}>
          {content && (
            <div className={classNames('col', styles.heroCol, styles.heroContent)}>
              <span className="category">Case study</span>
              <Markdown children={content} />
            </div>
          )}
          {image && (
            <div className={classNames('col', styles.heroCol, styles.heroAsset)}>
              <figure className={styles.heroImage}>
                <Image src={image} loading="eager" images={images} />
              </figure>
            </div>
          )}
        </div>
      </div>
      {/* <figure className={styles.heroBg}>
        {background && <img alt="Background" src={background} />}
      </figure> */}
    </section>
  )
}
