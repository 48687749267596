import React from 'react'
import loadable from '@loadable/component'
import camelCase from 'lodash/camelCase'

import Image from '../Image'
import classNames from 'classnames'
import styles from './case-image-section.module.sass'
// import ImageCarousel from '../Wrappers/ImageCarousel'
const LazyImageCarousel = loadable(() => import('../Wrappers/ImageCarousel'))
const ImageCarousel = props => <LazyImageCarousel {...props} />

export default function CaseImageSection({ title, images, classes }) {
  const className = classNames(
    styles.caseImageSection,
    images.length === 1 ? styles.singleImage : styles.imageSlider,
    classes && classes.split(' ').map(c => styles[camelCase(c)])
  )

  return (
    <section className={className}>
      <div className="container">
        <h2 className={styles.sectionTitle}>{title}</h2>
        {images.length === 1 && <Image src={images[0]} />}
        {images.length > 1 && <ImageCarousel images={images} />}
      </div>
    </section>
  )
}
