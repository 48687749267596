import React from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import imagePath from '../../utils/image-path'
// import Image from '../Image'
import styles from './case-section.module.sass'
import camelCase from 'lodash/camelCase'

// import CaseTestimonial from './CaseTestimonial'
const LazyCaseTestimonial = loadable(() => import('./CaseTestimonial'))
const CaseTestimonial = props => <LazyCaseTestimonial {...props} />

export default function CaseSection({ id, classes, title, testimonial, image, wideImage, text }) {
  var className = classNames(
    styles.caseSection,
    classes && classes.split().map(c => styles[camelCase(c)]),
    image && styles.hasImage,
    wideImage && styles.hasWideImage,
    testimonial && styles.hasTestimonial
  )
  return (
    <section id={id} className={className}>
      {wideImage && (
        <figure className={classNames(styles.wideImage, 'container')}>
          <img alt={title} src={imagePath(image)} />
        </figure>
      )}
      <div className={classNames(styles.sectionHeader, 'container', wideImage || !image ? styles.noImage : styles.hasImage)}>
        <header>
          <h2 className={styles.sectionTitle}>{title}</h2>
          <div className="accent"></div>
        </header>

        {/* {image && <Image image={imagePath(image)} alt={title} />} */}

        {image && !wideImage && (
          <figure className={styles.caseImageFloat}>
            <img alt={title} src={imagePath(image)} />
          </figure>
        )}
      </div>
      {text && (
        <Markdown className={classNames(styles.caseText, 'container')} options={{ forceWrapper: true, forceBlock: true }}>
          {text}
        </Markdown>
      )}
      {testimonial && <CaseTestimonial {...testimonial} />}
    </section>
  )
}
