import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Link } from 'gatsby'
import styles from './case-info.module.sass'
import startCase from 'lodash/startCase'

export default function CaseInfo({ project }) {
  return (
    <section className={styles.caseInfo}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>Client</h3>
            {project && <Markdown>{project.client}</Markdown>}
          </div>
          <div className="col">
            <h3>Project Duration</h3>
            {project && <Markdown>{project.duration}</Markdown>}
          </div>
          <div className="col">
            <h3>Services</h3>
            <p>
              {project.services.map((service, i) => (
                <Link key={i} to={'/' + service + '/'} className={styles.caseTag}>
                  {startCase(service).replace("Ux Ui", "UX/UI").replace("Mvp", "MVP")}
                </Link>
              ))}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
