import React from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'

import Layout from '../layout'
import PageHead from '../layout/PageHead'
import PageHeader from '../components/Sections/PageHeader'

import CaseHero from '../components/CaseSections/CaseHero'
import CaseInfo from '../components/CaseSections/CaseInfo'
import CaseSection from '../components/CaseSections/CaseSection'
import CaseUserStory from '../components/CaseSections/CaseUserStory'
import CaseImageSection from '../components/CaseSections/CaseImageSection'
import CaseSummary from '../components/CaseSections/CaseSummary'
import Section from '../components/Blog/SharedSection'

// import ImageCarousel from '../components/Wrappers/ImageCarousel'
const LazyImageCarousel = loadable(() => import('../components/Wrappers/ImageCarousel'))
const ImageCarousel = props => <LazyImageCarousel {...props} />

export default function CaseStudy({ data: { caseStudy, images } }) {
  const {
    path,
    image,
    intro,
    background,
    project,
    challenge,
    process,
    userflows,
    userstory,
    desktop,
    mobile,
    results,
    carousel = null,
    features,
    benefits
  } = caseStudy

  return (
    <Layout {...caseStudy}>
      <PageHead {...caseStudy} />
      <PageHeader path={path} />

      <CaseHero content={intro} image={image} background={background} images={images} />

      <CaseInfo project={project} />

      <CaseSection id="challenge" title="Client Challenge" {...challenge} />

      <CaseSection id="process" title="Service Process" {...process} classes="bg-light" />

      {userstory && <CaseUserStory {...userstory} />}

      {userflows && <CaseImageSection {...userflows} />}

      {desktop && <CaseImageSection {...desktop} />}

      {mobile && <CaseImageSection {...mobile} />}

      <CaseSection id="results" title="Project Results" {...results} />

      {carousel && <ImageCarousel images={carousel} />}

      <CaseSummary features={features} benefits={benefits} />

      <Section name="contact-us-case-study" />
      <Section name="main-imageline" />
    </Layout>
  )
}

export const query = graphql`
  query Case($id: String!, $path: String!) {
    caseStudy: caseStudiesYaml(id: { eq: $id }) {
      path
      title
      image
      description
      intro
      background
      project {
        client
        duration
        services
      }
      challenge {
        text
        testimonial {
          text
          image
          name
          position
        }
      }
      process {
        image
        text
        testimonial {
          text
          image
          name
          position
        }
      }
      userflows {
        title
        images
      }
      userstory {
        title
        mapping {
          user {
            image
            name
            company
            goals
          }
          story {
            title
            set
          }
        }
      }
      desktop {
        title
        images
        classes
      }
      results {
        text
        image
        testimonial {
          text
          image
          name
          position
        }
      }
      features
      benefits
      tags
    }
    images: allImageSharp(filter: { parent: { internal: { description: { regex: $path } } } }) {
      nodes {
        fluid {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
