import React from 'react'
import Markdown from 'markdown-to-jsx'
import styles from './case-summary.module.sass'

import FeaturesIcon from '../../../static/ui/features-icon.inline.svg'
import BenefitsIcon from '../../../static/ui/benefits-icon.inline.svg'

export default function CaseSummary({ features, benefits }) {
  return (
    <section className={styles.caseSummary}>
      <div className="container">
        <div className={styles.sectionInner}>
          <div>
            <h2 className={styles.featuresTitle}>
              <FeaturesIcon />
              Deliverables
            </h2>
            <Markdown>{features}</Markdown>
          </div>
          <div>
            <h2 className={styles.benefitsTitle}>
              <BenefitsIcon />
              Benefits
            </h2>
            <Markdown>{benefits}</Markdown>
          </div>
        </div>
      </div>
    </section>
  )
}
