import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PageSection from '../../components/PageSection'

export default function Section({ name, classes }) {
  const { sharedSections } = useStaticQuery(graphql`
    {
      sharedSections: allSectionsYaml {
        nodes {
          id
          name
          json
        }
      }
    }
  `)

  const sharedSectionsMap = {}
  sharedSections.nodes.forEach(({ name, section, sections, json }) => {
    sharedSectionsMap[name] = json ? JSON.parse(json || '{}') : section || sections[0]
  })

  if (!sharedSectionsMap[name]) {
    return null
  }

  sharedSectionsMap[name].classes = sharedSectionsMap[name].classes + ' ' + classes

  return (
    <div className="row-full">
      <PageSection section={sharedSectionsMap[name]} />
    </div>
  )
}
